import moment from "moment";

export enum RoutePermittedRole {
  Admin = "admin",
  User = "user",
  Demo = "demo",
}

export enum RouteTitle {
  "rcm" = "RCM",
  "ship-dashboard" = "Ship Dashboard",
  "ship-performance" = "Compliance",
  "data-input" = "Data Input",
  "excel-noon-report" = "Excel Noon Report",
  "upload-files" = "Upload Files",
  "noon-report-submission-form" = "Noon Report Submission Form",
  "notifications" = "List Notifications"
}

export const initialDate = moment().subtract(15, 'days').format('YYYY-M-D');

export const today = moment().format('YYYY-M-D');

export const initialYear = '2022'; // first year with data