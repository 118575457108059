import { createSlice } from '@reduxjs/toolkit';
import { ExcessFuel } from 'types/models/ExcessFuel';

const initialState: ExcessFuel = {
    excessFuelData: {
        data: null,
        status: 'idle',
        error: null
    },
};

const excessFuelConsumptionSlice = createSlice({
    name: 'excessFuelConsumption',
    initialState,
    reducers: {
        loadExcessFuelDataStart(state) {
            state.excessFuelData.status = 'loading';
        },
        loadExcessFuelDataSuccess(state, action) {
            state.excessFuelData.status = 'succeeded';
            state.excessFuelData.data = action.payload.map(item => ({
                fuelWeather: item.fuelWeather,
                fuelFouling: item.fuelFouling,
                fuelEngineEfficiency: item.fuelEngineEfficiency,
                fuelAuxiliaryEngineEfficiency: item.fuelAuxiliaryEngineEfficiency,
                fuelTrim: item.fuelTrim,
            }));
            state.excessFuelData.error = null;
        },
        loadExcessFuelDataFailure(state, action) {
            state.excessFuelData.status = 'error';
            state.excessFuelData.error = action.payload;
        },

    },
});

export const {
    loadExcessFuelDataStart,
    loadExcessFuelDataSuccess,
    loadExcessFuelDataFailure
} = excessFuelConsumptionSlice.actions;

export default excessFuelConsumptionSlice.reducer;
