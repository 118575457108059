import { Dispatch } from "redux";
import jwtAxios from "@crema/services/auth/jwt-auth";
import {
    loadNotificationsStart,
    loadNotificationsSuccess,
    loadNotificationsFailure
} from "../../redux/reducers/Notifications";

export const getNotifications = (
    shipId: number,
    pageNumber: number,
    pageSize: number
) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(loadNotificationsStart());
        jwtAxios.get(`/notifications/${shipId}`, {
            params: {
                shipId,
                pageNumber,
                pageSize,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(loadNotificationsSuccess(res?.data));
                } else {
                    dispatch(loadNotificationsFailure(res?.data?.message));
                }
            })
            .catch((error) => {
                dispatch(loadNotificationsFailure(error.response?.data?.message));
            });
    };
};