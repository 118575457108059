import React from 'react';
import { Stack, Skeleton, SkeletonProps } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface SkeletonLoaderProps {
  count?: number;
  width?: string | number;
  height?: string | number;
  gap?: string | number;
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  direction?: 'row' | 'column';
  p?: string | number;
  sx?: SxProps<Theme>;
  skeletonProps?: SkeletonProps;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  count = 4,
  width = '100%',
  height = 20,
  gap = 4,
  direction,
  justifyContent = 'space-around',
  alignItems = 'center',
  p = 2,
  sx = { bgcolor: 'blue.whale' },
  skeletonProps,
}) => {
  return (
    <Stack
      gap={gap}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      p={p}
      sx={sx}
    >
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton key={index} width={width} height={height} {...skeletonProps} />
      ))}
    </Stack>
  );
};

export default SkeletonLoader;
