import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { NotificationsData, Notification } from '../../types/models/Notification';

const notificationsAdapter = createEntityAdapter({
    selectId: (notification: Notification) => notification.notificationId,
});

const initialState = notificationsAdapter.getInitialState({
    pagination: null,
    status: 'idle',
    error: null,
});

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        loadNotificationsStart(state) {
            state.status = 'loading';
        },
        loadNotificationsSuccess(state, action) {
            state.status = 'succeeded';
            const {
                data,
                pageNumber,
                pageSize,
                totalPages,
                totalRecords,
                firstPage,
                lastPage,
            } = action.payload;
            notificationsAdapter.setAll(state, data);
            state.pagination = { pageNumber, pageSize, totalPages, totalRecords, firstPage, lastPage };
            state.error = null;
        },
        loadNotificationsFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },
    },

});

export const {
    loadNotificationsStart,
    loadNotificationsSuccess,
    loadNotificationsFailure,
} = notificationsSlice.actions;

// Exporting selectors to access the state
export const notificationsSelectors = notificationsAdapter.getSelectors<RootState>(
    (state) => state.notifications
);

export default notificationsSlice.reducer;