import { createSlice } from '@reduxjs/toolkit';
import { FuelConsumption } from 'types/models/FuelConsumption';

const initialState: FuelConsumption = {
    optimalData: {
        data: null,
        status: 'idle',
        error: null,
    },
    pointsData: {
        data: null,
        status: 'idle',
        error: null,
    },
};

const fuelConsumptionSlice = createSlice({
    name: 'fuelConsumption',
    initialState,
    reducers: {
        // Optimal Data Loaders
        loadOptimalDataStart(state) {
            state.optimalData.status = 'loading';
        },
        loadOptimalDataSuccess(state, action) {
            state.optimalData.status = 'succeeded';
            state.optimalData.data = action.payload.map(item => ({
                speed: item.speed,
                fuelConsumption: item.fuel_consumption,
            }));
            state.optimalData.error = null;
        },
        loadOptimalDataFailure(state, action) {
            state.optimalData.status = 'error';
            state.optimalData.error = action.payload;
        },

        // Points Data Loaders
        loadPointsDataStart(state) {
            state.pointsData.status = 'loading';
        },
        loadPointsDataSuccess(state, action) {
            state.pointsData.status = 'succeeded';
            state.pointsData.data = action.payload.map(item => ({
                consPerfuel: item.consPerFuel,
                speed: item.speed,
                fuelConsumption: item.fuel_consumption,
            }));
            state.pointsData.error = null;
        },
        loadPointsDataFailure(state, action) {
            state.pointsData.status = 'error';
            state.pointsData.error = action.payload;
        },
    },
});

export const {
    loadOptimalDataStart,
    loadOptimalDataSuccess,
    loadOptimalDataFailure,
    loadPointsDataStart,
    loadPointsDataSuccess,
    loadPointsDataFailure,
} = fuelConsumptionSlice.actions;

export default fuelConsumptionSlice.reducer;
