import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

const ShipPerformance = React.lazy(() => import("./ShipPerformance"));
const Overview = React.lazy(() => import("./Overview"));
const Rcm = React.lazy(() => import("./Rcm"));
const ShipDashboard = React.lazy(() => import("./ShipDashboard"));
const DataInput = React.lazy(() => import("./DataInput"));
const NoonReport = React.lazy(() => import("./DataInput/NoonReport"));
const UploadFiles = React.lazy(() => import("./DataInput/UploadFiles"));
const FormNoonReport = React.lazy(() => import("./DataInput/Form"));

export const samplePagesConfigs = [
  {
    path: "/seaquest/data-input",
    element: <DataInput />,
    permittedRole: [RoutePermittedRole.User]
  },
  {
    path: "/seaquest/data-input/noon-report-submission-form",
    element: <FormNoonReport />,
    permittedRole: [RoutePermittedRole.User]
  },
  {
    path: "/seaquest/data-input/excel-noon-report",
    element: <NoonReport />,
    permittedRole: [RoutePermittedRole.User]
  },
  {
    path: "/seaquest/data-input/upload-files",
    element: <UploadFiles />,
    permittedRole: [RoutePermittedRole.User]
  },
  {
    path: "/seaquest/overview",
    element: <Overview />,
    permittedRole: [RoutePermittedRole.Admin],
  },
  {
    path: "/seaquest/RCM",
    element: <Rcm />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Demo, RoutePermittedRole.Admin],
  },
  {
    path: "/seaquest/ship-dashboard",
    element: <ShipDashboard />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
  },
  {
    path: "/seaquest/ship-performance",
    element: <ShipPerformance />,
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
  },
];
