import React from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import AppCheckbox from '@crema/core/AppCheckbox';
import IntervalSelectBox from '../SelectBox/Interval';
import DayOfWeekSelectBox from '../SelectBox/DayOfWeek';
import TimePicker from '../TimePicker';
import { RowStack } from '../index.style';
import AppDialog from '@crema/core/AppDialog'

interface NotificationCalendarDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (event: React.FormEvent) => Promise<void>;
    theme: any;
    intervalType: string;
    dayOfWeek: string;
    time: Date | null;
    isSelectIntervOpen: boolean;
    setSelectIntervOpen: (open: boolean) => void;
    isDayofWeekOpen: boolean;
    setDayofWeekOpen: (open: boolean) => void;
    weekdays: string[];
    checked: boolean;
    onCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onIntervChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDayChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onTimeChange: (newValue: Date | null) => void;
    clientTimezone: string;
}

const NotificationCalendarDialog: React.FC<NotificationCalendarDialogProps> = ({
    open,
    onClose,
    onSubmit,
    theme,
    intervalType,
    dayOfWeek,
    time,
    isSelectIntervOpen,
    setSelectIntervOpen,
    isDayofWeekOpen,
    setDayofWeekOpen,
    weekdays,
    checked,
    onCheckBoxChange,
    onIntervChange,
    onDayChange,
    onTimeChange,
    clientTimezone,
}) => (
    <AppDialog
        open={open}
        onClose={onClose}
        title={<IntlMessages id="common.scheduleNotifications" />}
        actionTitle={<Typography variant='body1' color='text.light'>OK</Typography>}
        onAction={onSubmit}
        dividers
        sxStyle={{
            boxShadow: theme.shadows[2],
            "& .MuiDialogContent-root": {
                borderColor: "marine.main",
                p: 0,
            },
            "& .MuiDialog-paper": {
                minWidth: 461,
                maxWidth: '100%',
                borderRadius: 2.5,
                backgroundImage: 'none',
                bgcolor: 'blue.whale',
            }, '& .simplebar-track.simplebar-horizontal': {
                display: 'none',
            },
        }}
        mainContentStyle={{ px: 0, minHeight: 'auto' }}
        actionProps={{ disabled: time === null }}
    >
        <Stack divider={<Divider aria-hidden="true" flexItem sx={{ borderColor: "marine.main" }} />}>
            <Stack py={2.5} px={6} gap={2.5}>
                <RowStack sx={{ width: 461 }}>
                    <Typography variant='body1' color='white.main'>
                        Every
                    </Typography>
                    <IntervalSelectBox
                        intervalType={intervalType}
                        onChange={onIntervChange}
                        isOpen={isSelectIntervOpen}
                        onOpen={() => setSelectIntervOpen(true)}
                        onClose={() => setSelectIntervOpen(false)}
                    />
                    <RowStack direction={intervalType === 'weekly' ? 'row' : 'row-reverse'}>
                        <RowStack sx={{ visibility: intervalType === 'weekly' ? 'visible' : 'hidden' }}>
                            <Typography variant='body1' color='white.main'>
                                on
                            </Typography>
                            <DayOfWeekSelectBox
                                dayOfWeek={dayOfWeek}
                                onChange={onDayChange}
                                isOpen={isDayofWeekOpen}
                                onOpen={() => setDayofWeekOpen(true)}
                                onClose={() => setDayofWeekOpen(false)}
                                weekdays={weekdays}
                            />
                        </RowStack>
                        <RowStack>
                            <Typography variant='body1' color='white.main'>
                                at
                            </Typography>
                            <TimePicker
                                time={time}
                                onChange={onTimeChange}
                            />
                        </RowStack>
                    </RowStack>
                </RowStack>
                <RowStack py={1} sx={{ fontSize: 10, color: 'tertiary.main' }}>
                    <IntlMessages id="common.timezone" />: {''} {clientTimezone}
                </RowStack>
            </Stack>
            <RowStack px={6} sx={{ height: 55, overflow: 'hidden' }}>
                <Typography color="text.light" variant="body1">
                    <IntlMessages id="common.applyToVessels" />
                </Typography>
                <AppCheckbox
                    checked={checked}
                    onChange={onCheckBoxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </RowStack>
        </Stack>
    </AppDialog>
);

export default NotificationCalendarDialog;
