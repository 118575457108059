import { useCallback } from "react";
import AppMessageView from "@crema/core/AppMessageView";
import AppLoader from "@crema/core/AppLoader";
import { AppState } from "../../../redux/store";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { hideMessage } from "../../../redux/reducers/Common";

const AppInfoView = () => {
  const dispatch = useAppDispatch();
  const {
    loading,
    message,
    error,
    updateError,
    updatingContent,
    updateStatus,
    status
  } = useAppSelector<AppState["common"]>(
    ({ common }) => common
  )

  //ship's selector
  const { loading : coreLoading } = useAppSelector<AppState["core"]>(
    ({ core }) => core
  )

  const handleClose = useCallback(() => {
    dispatch(hideMessage());
  }, [dispatch]);

  const isLoading = loading || updatingContent || coreLoading;
  return (
    <>
      {isLoading && <AppLoader />}

      {updateStatus === "succeeded" && <AppMessageView
        open={updateStatus === "succeeded"}
        onClose={handleClose}
        variant="success"
        message={message}
      />}

      {(updateStatus === "error" || status === "error") && <AppMessageView
        open={updateStatus === "error" || status === "error"}
        onClose={handleClose}
        variant="error"
        message={error || updateError}
      />}
    </>
  );
};

export default AppInfoView;
