import { combineReducers } from "redux";
import Settings from "./Setting";
import Core from "./Core";
import Common from "./Common";
import Dashboard from "./Dashboard";
import AccountReducer from "./Account";
import ShipReducer from "./Ships";
import SubSystemReducer from "./Subsystems";
import SensorsReducer from "./Sensors";
import sensorsWarningsReducer from "./SensorsWarnings";
import fuelConsumptionReducer from "./FuelConsumption";
import excessFuelConsumptionReducer from "./ExcessFuelConsumption";
import engineEfficiencyReducer from "./EngineEfficiency";
import ShipComplianceReducer from "./ShipCompliance";
import notificationsReducer from "./Notifications";
import notificationsConfigReducer from "./NotificationsConfig";
import electricalsFaultsReducers from "./ElectricalsFaults"
import { apiService } from "@crema/services/RTK/api";


const reducers = () =>
  combineReducers({
    settings: Settings,
    common: Common,
    core: Core,
    dashboard: Dashboard,
    account: AccountReducer,
    ships: ShipReducer,
    subsystems: SubSystemReducer,
    sensors: SensorsReducer,
    sensorsWarnings: sensorsWarningsReducer,
    cii: ShipComplianceReducer,
    fuelConsumption: fuelConsumptionReducer,
    excessFuelConsumption: excessFuelConsumptionReducer,
    engineEfficiency: engineEfficiencyReducer,
    notifications: notificationsReducer,
    notificationsConfig: notificationsConfigReducer,
    electricalsFaults : electricalsFaultsReducers,
    [apiService.reducerPath]: apiService.reducer,
  });

export default reducers;
