import jwtAxios from "../auth/jwt-auth";
import MockAdapter from "axios-mock-adapter";

// const MockAdapter = require("axios-mock-adapter");

// export default new MockAdapter(jwtAxios, { delayResponse: 100 });

const mock = new MockAdapter(jwtAxios, { delayResponse: 100 });

export default mock;
