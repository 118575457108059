import React from 'react';
import { SelectBox, CustomMenuItem } from '../index.style';
import { ReactComponent as ArrowDown } from "../../../../assets/icon/arrow-down.svg";

interface DayOfWeekSelectBoxProps {
  dayOfWeek: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  weekdays: string[];
}

const DayOfWeekSelectBox: React.FC<DayOfWeekSelectBoxProps> = ({ dayOfWeek, onChange, isOpen, onOpen, onClose, weekdays }) => (
  <SelectBox
    id="simple-select"
    sx={{
      "& .MuiSelect-select": {
        color: isOpen ? 'tertiary.main' : 'text.light', px: 2,
      }
    }}
    onOpen={onOpen}
    onClose={onClose}
    open={isOpen}
    MenuProps={{
      elevation: 0, //no shadow
      sx: {
        '& .MuiPaper-root': {
          mt: 2,
          bgcolor: 'marine.main',
          backgroundImage: 'none',
          paddingRight: 0,
        },
        '& .MuiMenu-list': { py: 0 },
        '& .MuiOutlinedInput-input': { pr: 0, }
      }
    }}
    IconComponent={(props) => <ArrowDown className={props.className} />}
    value={dayOfWeek}
    onChange={onChange}
  >
    {weekdays.map((day) =>
      <CustomMenuItem key={day} value={day}>{day}</CustomMenuItem>
    )}
  </SelectBox>
);

export default DayOfWeekSelectBox;
