import React from "react";
import moment from "utils/momentUtils";
import { Notification } from "types/models/Notification";
import { ListItemButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


const generateSrmUrl = (page: number, fromDate: string | Date, toDate: string | Date, subSystemId: string): string => {
    const params = new URLSearchParams({
        page: page.toString(),
        from: moment(fromDate).format('YYYY-MM-DD').toString(),
        to: moment(toDate).format('YYYY-MM-DD').toString(),
        subSystemId: subSystemId,
    });
    return `/seaquest/rcm?${params.toString()}`;
};
interface NotificationItemProps {
    notification: Notification;
    handleClose?: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, handleClose }) => {
    const navigate = useNavigate();

    const onNotificationClick = () => {
        navigate(generateSrmUrl(
            1,
            notification?.fromDate,
            notification?.toDate,
            notification?.subsystemId
        ));
        handleClose()
    };
    return (
        <ListItemButton
            key={notification.notificationId}
            sx={{
                width: "100%", height: "100%",
                '&:hover': { bgcolor: 'marine.main' },
            }}
            onClick={onNotificationClick}
        >
            <Typography color="text.light" variant='body2' sx={{ width: 3 / 4, lineHeight: 2 }} >
                {notification.notificationMessage}
            </Typography>

            <Stack
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ width: 1 / 4, height: "100%", }}
            >
                <Typography variant='body2' sx={{ color: 'tertiary.main', textAlign: 'center' }}>
                    {moment((notification.createdAt)).fromNow()}
                </Typography>
            </Stack>
        </ListItemButton >
    );
};

export default NotificationItem;
