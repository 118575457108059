import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

interface TimePickerProps {
  time: Date | null;
  onChange: (newValue: Date | null) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ time, onChange }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <TimeField
      ampm={false}
      value={time}
      onChange={onChange}
      sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'marine.main', pr: 0 } }}
      InputProps={{
        sx: {
          letterSpacing: '0.1em',
          width: 75,
          height: 55,
          '& .MuiInputBase-input': {
            color: 'text.light',
          },
          '& .MuiOutlinedInput-notchedOutline , .MuiOutlinedInput-root.Mui-focused': {
            borderWidth: '0px !important',
          },
        }
      }}
    />
  </LocalizationProvider>
);

export default TimePicker;
