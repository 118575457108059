import { setHours, setMinutes, setSeconds, parse } from 'date-fns';

// Converts an ISO 8601 date string to a simplified date-time format "YYYY-MM-DD HH:MM".
export const formatDateAndTime: (isoDate: string) => string = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are zero-indexed, +1 to adjust
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minute}`;
};

export const formatMonthNumeric: (isoDate: string) => string = (isoDate) => {
    const date = new Date(isoDate);
    const month = date.getMonth() + 1; // getMonth() returns 0 for January, 11 for December
    return month.toString().padStart(2, '0'); // Pads the month with a leading zero if necessary
};


// Converts an ISO 8601 date string to a string representing the month in abbreviated format (e.g., Jan, Feb, Mar).
// formatMonth("2023-05-15")); returns 'May'
export const formatMonth: (isoDate: string) => string = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('default', { month: 'short' });
};


// Extracts the year from an ISO 8601 date string and returns it as a string.
// formatYear("2023-05-15")); returns '2023'
export const formatYear: (isoDate: string) => string = (isoDate) => {
    const date = new Date(isoDate);
    return date.getFullYear().toString(); // Convert the year to a string
};


// Fills in missing months for each year present in a data array. 
// Each month that does not have a data entry for a given year is filled with a new entry, 
// where the timestamp is the first day of the missing month.
export const fillFutureMonths = (data: any[]) => {
    const filledData = [];
    const years = new Set(
        data.map(item => new Date(item.timestamp).getFullYear() as number) // Collect years from data and ensure they are treated as numbers.
    );

    years.forEach((year: number) => { // Process each year found in the data.
        const dateSet = new Set(
            data.filter(item => new Date(item.timestamp).getFullYear() === year)
                .map(item => new Date(item.timestamp).getMonth())
        );

        for (let month = 0; month < 12; month++) {
            if (!dateSet.has(month)) {
                const formattedMonth = month + 1;
                const paddedMonth = formattedMonth.toString().padStart(2, '0'); // Ensure the month is two digits
                const timestamp = `${year}-${paddedMonth}-01`; // Create the timestamp as 'YYYY-MM-01'

                filledData.push({
                    timestamp: timestamp,
                });
            }
        }
    });

    // Combine original data with the filled data and sort by date
    const completeData = data.concat(filledData).sort((a, b) => (a.timestamp) - (b.timestamp));
    return completeData;
};


// Generates an array of strings for each year in a range from the specified start year up to the current year.
export const generateYearRange = (startYear: string) => {
    const currentYear = new Date().getFullYear();
    const startYearInt = parseInt(startYear, 10);

    // Check if the parsed startYear is a valid number and not greater than the current year
    if (isNaN(startYearInt) || startYearInt > currentYear) {
        throw new Error("Invalid start year or start year is in the future.");
    }

    // Generate an array from startYear to currentYear
    const years = [];
    for (let year = startYearInt; year <= currentYear; year++) {
        years.push(year.toString());
    }

    return years;
};


export const getClientTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};


// Parse a time string and set the hours, minutes, and seconds on a Date object.
export const parseTimeString = (timeString: string): Date => {
    // Parse the time string into a Date object with today's date
    const parsedDate = parse(timeString, 'HH:mm:ss', new Date());
  
    // Set the hours, minutes, and seconds on a new Date object
    const now = new Date();
    const resultDate = setHours(setMinutes(setSeconds(now, parsedDate.getSeconds()), parsedDate.getMinutes()), parsedDate.getHours());
  
    return resultDate;
  };