import { ReactNode } from 'react'
import { SxProps, Box, Stack, Typography, StackProps, TypographyProps } from '@mui/material';
import { ReactComponent as ErrorIcon } from "assets/icon/error.svg";

interface ErrorDisplayCardProps {
    children: ReactNode;
    flexContainer?: StackProps;
    sxStyle?: SxProps;
    typographyProps?: TypographyProps;
    iconContainer?: SxProps;
    [x: string]: any;
};

const ErrorDisplayCard = ({
    sxStyle,
    children,
    iconContainer,
    flexContainer,
    typographyProps
}: ErrorDisplayCardProps) => {
    return (
        <Stack alignItems='center' justifyContent='center' gap={2} sx={{ ...sxStyle }} {...flexContainer}>
            <Box sx={{ width: 1 / 5, height: 1 / 5, ...iconContainer }}>
                <ErrorIcon />
            </Box>
            <Typography {...typographyProps}>
                {children}
            </Typography>
        </Stack>
    );
};

export default ErrorDisplayCard;