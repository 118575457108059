import React, { useEffect, useState } from 'react';
import AppLoader from "@crema/core/AppLoader";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { setNotificationConfig } from "../../../redux/actions/NotificationConfig";
import { getClientTimezone, parseTimeString } from 'utils/dateUtils';
import NotificationCalendarDialog from './Dialog';
import { eachDayOfInterval, format } from 'date-fns';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';

const daysOfWeek = eachDayOfInterval({
  start: new Date(), end: new Date(new Date().setDate(new Date().getDate() + 6))
});

const weekdays = daysOfWeek.map((day) => format(day, 'EEEE'));

interface NotificationCalendarProps {
  open: boolean;
  onClose: () => void;
}

const NotificationCalendar: React.FC<NotificationCalendarProps> = ({ open, onClose }) => {
  const selectedShipId = useAppSelector((state) => state.ships?.selectedShip?.shipId);
  const { status: configStatus, data: configData } = useAppSelector((state) => state?.notificationsConfig);

  const { theme } = useThemeContext();
  const dispatch = useAppDispatch();
  const [isSelectIntervOpen, setSelectIntervOpen] = useState(false);
  const [isDayofWeekOpen, setDayofWeekOpen] = useState(false);

  const [dataState, setDataState] = useState({
    checked: true,
    intervalType: 'daily',
    dayOfWeek: weekdays[0] ? weekdays[0] : '',
    time: null,
  });
  const { checked, intervalType, dayOfWeek, time } = dataState;
  const clientTimezone = getClientTimezone();

  useEffect(() => {
    if (configData) {
      setDataState((prevState) => ({
        ...prevState,
        intervalType: configData?.intervalType,
        dayOfWeek: configData?.notificationDay ? configData?.notificationDay : weekdays[0] ? weekdays[0] : '',
        time: parseTimeString(configData?.localNotificationTime),
      }));
    }
    return () => {
      setDataState((prevState) => ({
        ...prevState,
        intervalType: 'daily',
        dayOfWeek: weekdays[0] ? weekdays[0] : '',
        time: null,
      }));
    }
  }, [configData, open]);

  const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataState((prevState) => ({
      ...prevState,
      checked: event.target.checked,
    }));
  };

  const onIntervChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataState((prevState) => ({
      ...prevState,
      intervalType: event.target.value as string,
    }));
  }

  const onDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataState((prevState) => ({
      ...prevState,
      dayOfWeek: event.target.value,
    }));
  };

  const onTimeChange = (newValue: Date | null) => {
    setDataState((prevState) => ({
      ...prevState,
      time: newValue
    }));
  }

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formattedTime = time ? format(time, 'HH:mm:ss') : '';
    dispatch(setNotificationConfig(
      selectedShipId as number,
      intervalType,
      formattedTime,
      (intervalType === 'weekly') ? dayOfWeek : null,
      clientTimezone,
      checked,
    ));
    onClose();
  };

  if (configStatus === 'loading') return <AppLoader />;

  return (
    <NotificationCalendarDialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      theme={theme}
      intervalType={intervalType}
      dayOfWeek={dayOfWeek}
      time={time}
      isSelectIntervOpen={isSelectIntervOpen}
      setSelectIntervOpen={setSelectIntervOpen}
      isDayofWeekOpen={isDayofWeekOpen}
      setDayofWeekOpen={setDayofWeekOpen}
      weekdays={weekdays}
      checked={checked}
      onCheckBoxChange={onCheckBoxChange}
      onIntervChange={onIntervChange}
      onDayChange={onDayChange}
      onTimeChange={onTimeChange}
      clientTimezone={clientTimezone}
    />
  );
};

export default NotificationCalendar;