import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Common } from '../../types/models/Common';

const initialState: Common = {
  loading: false,
  status: 'idle',
  updateStatus: 'idle',
  error: null,
  updateError: null,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    loadStart(state) {
      state.status = 'loading';
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    loadSuccess(state, action: PayloadAction<string>) {
      state.status = 'succeeded';
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    loadFailure(state, action: PayloadAction<string>) {
      state.status = 'error';
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    setStart(state) {
      state.updateStatus = 'loading';
      state.updatingContent = true;
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    setSuccess(state, action: PayloadAction<string>) {
      state.updateStatus = 'succeeded';
      state.loading = false;
      state.updatingContent = false;
      state.updateError = null;
      state.message = action.payload;
    },
    setFailure(state, action: PayloadAction<string>) {
      state.updateStatus = 'error';
      state.updateError = action.payload;
      state.loading = false;
      state.updatingContent = false;
      state.message = null;
    },

    showMessage(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    hideMessage(state) {
      state.updateStatus = 'idle';
      state.status= 'idle';
      state.loading = false;
      state.updatingContent = false;
      state.error = null;
      state.message = null;
    },
    toggleAppDrawer(state) {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    },
  },
});

export const {
  loadStart,
  loadSuccess,
  loadFailure,

  setStart,
  setSuccess,
  setFailure,

  showMessage,
  hideMessage,
  toggleAppDrawer,
} = commonSlice.actions;

export default commonSlice.reducer;