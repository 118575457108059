import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { SubSystem } from 'types/models/Ship';

const subsystemsAdapter = createEntityAdapter({
    selectId: (subsystem: SubSystem) => subsystem.subsystemId,
});

const initialState = subsystemsAdapter.getInitialState({
    pagination: null,
    status: 'idle',
    error: null
});

const subsystemsSlice = createSlice({
    name: 'subsystems',
    initialState,
    reducers: {
        loadSubsystemsStart(state) {
            state.status = 'loading';
        },
        loadSubsystemsSuccess(state, action) {
            const {
                data,
                totalPages,
                totalRecords,
                pageNumber,
                pageSize,
                firstPage,
                lastPage,
                nextPage,
                previousPage
            } = action.payload;
            subsystemsAdapter.setAll(state, data);
            state.pagination = { pageNumber, pageSize, totalPages, totalRecords, firstPage, lastPage, nextPage, previousPage };
            state.status = 'succeeded'
        },
        loadSubsystemsFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },
    }
});

export const {
    loadSubsystemsStart,
    loadSubsystemsSuccess,
    loadSubsystemsFailure
} = subsystemsSlice.actions;

// Exporting selectors to access the state
export const subsystemsSelectors = subsystemsAdapter.getSelectors<RootState>(
    (state) => state.subsystems
);

export default subsystemsSlice.reducer;