import { createSlice } from '@reduxjs/toolkit';
import { EngineEfficiency } from 'types/models/EngineEfficiency';


const initialState: EngineEfficiency = {
    optimalData: {
        data: null,
        status: 'idle',
        error: null,

    },
    pointsData: {
        data: null,
        status: 'idle',
        error: null,
    },
};

const engineEfficiencySlice = createSlice({
    name: 'engineEfficiency',
    initialState,
    reducers: {
        // Optimal Data Loaders
        loadEngEfOptimalDataStart(state) {
            state.optimalData.status = 'loading';
        },
        loadEngEfOptimalDataSuccess(state, action) {
            state.optimalData.status = 'succeeded';
            state.optimalData.data = action.payload.map(item => ({
                load: item.load,
                efficiency: item.efficiency,
            }));
            state.optimalData.error = null;
        },
        loadEngEfOptimalDataFailure(state, action) {
            state.optimalData.status = 'error';
            state.optimalData.error = action.payload;
        },

        // Points Data Loaders
        loadEngEfPointsDataStart(state) {
            state.pointsData.status = 'loading';
        },
        loadEngEfPointsDataSuccess(state, action) {
            state.pointsData.status = 'succeeded';
            state.pointsData.data = action.payload.map(item => ({
                consPerfuel: item.consPerFuel,
                load: item.load,
                efficiency: item.efficiency,
            }));
            state.pointsData.error = null;
        },
        loadEngEfPointsDataFailure(state, action) {
            state.pointsData.status = 'error';
            state.pointsData.error = action.payload;
        },
    },
});

export const {
    loadEngEfOptimalDataStart,
    loadEngEfOptimalDataSuccess,
    loadEngEfOptimalDataFailure,
    loadEngEfPointsDataStart,
    loadEngEfPointsDataSuccess,
    loadEngEfPointsDataFailure,
} = engineEfficiencySlice.actions;

export default engineEfficiencySlice.reducer;