import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Core } from '../../types/models/Core';

const initialState: Core = {
  loading: false,
  status: 'idle',
  error: null,
  message: null,
};

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    loadStart(state) {
      state.status = 'loading';
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    loadSuccess(state, action: PayloadAction<string>) {
      state.status = 'succeeded';
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    loadFailure(state, action: PayloadAction<any>) {
      state.status = 'error';
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    showMessage(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    clearState(state) {
      state.updateStatus = 'idle';
      state.status = 'idle';
      state.loading = false;
      state.updatingContent = false;
      state.error = null;
      state.message = null;
    },
  },
});

export const {
  loadStart,
  loadSuccess,
  loadFailure,

  showMessage,
  clearState,
} = coreSlice.actions;

export default coreSlice.reducer;