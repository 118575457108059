import { Notification } from "types/models/Notification";

export const mockNotificationsData/* : Notification[] */ = [
  {
    "notificationId": 50,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID TOP_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.941118Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString1"
  },
  {
    "notificationId": 49,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID PMP7_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.928324Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString2"
  },
  {
    "notificationId": 48,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID PMP4_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.915564Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString3"
  },
  {
    "notificationId": 47,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID PMP2_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.900091Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString4"
  },
  {
    "notificationId": 46,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID MOT2_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.859967Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString5"
  },
  {
    "notificationId": 45,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID MOT1_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.793457Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString6"
  },
  {
    "notificationId": 44,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID FLT3_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.780525Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString7"
  },
  {
    "notificationId": 43,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID FLT2_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.667071Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString8"
  },
  {
    "notificationId": 42,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID ARB_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.632099Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString9"
  },
  {
    "notificationId": 41,
    "shipId": 1,
    "notificationMessage": "No warnings found for subsystem with ID ARA_1 in the past 24 hours.",
    "createdAt": "2024-05-31T08:57:00.454896Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString10"
  }
];

export const mockNotificationsData2/* : Notification[] */ = [
  {
    "notificationId": 28,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID TOP_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:01.261901Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString11"
  },
  {
    "notificationId": 24,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID PMP5_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:01.032558Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString12"
  },
  {
    "notificationId": 18,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID PMP4_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.638824Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString13"
  },
  {
    "notificationId": 17,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID PMP3_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.548051Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString14"
  },
  {
    "notificationId": 16,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID MOT5_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.487317Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString15"
  },
  {
    "notificationId": 15,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID MOT1_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.472479Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString16"
  },
  {
    "notificationId": 14,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID FLT4_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.353063Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString17"
  },
  {
    "notificationId": 13,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID FLT1_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.292417Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString18"
  },
  {
    "notificationId": 12,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID ARD_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.237437Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString19"
  },
  {
    "notificationId": 11,
    "shipId": 2,
    "notificationMessage": "No warnings found for subsystem with ID ARC_2 in the past 24 hours.",
    "createdAt": "2024-05-30T09:00:00.102922Z",
    "markAsRead": false,
    "enabled": false,
    "subsystemId": "randomString20"
  }
];
