import React from 'react';


const NotificationPage = React.lazy(() => import('./UserNotifications'));

export const notificationPage = [
    {
        path: "/notifications",
        element: <NotificationPage />,
    },
];
