import moment from 'moment';

moment.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a few seconds',
        ss: '%d seconds',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        w: "a w",
        ww: "%d w",
        M: "a mo",
        MM: "%d mo",
        y: "a y",
        yy: "%d y"
    }
});

export default moment;