import { ReactNode } from 'react'
import { SxProps, Stack, Typography, StackProps, TypographyProps } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';

interface NoContentCardProps {
    title: ReactNode;
    iconContainer?: SxProps;
    message?: React.ReactNode;
    sxStyle?: SxProps;
    flexContainer?: StackProps;
    titleStyle?: TypographyProps;
    messageStyle?: TypographyProps;
};

const NoContentCard = ({
    message,
    sxStyle,
    iconContainer,
    flexContainer,
    titleStyle,
    messageStyle,
    title
}: NoContentCardProps) => {
    return (
        <Stack
            gap={5}
            justifyContent='center'
            alignItems='center'
            sx={{ color: "text.secondary", ...sxStyle }}
            {...flexContainer}
        >
            <InboxIcon sx={{ fontSize: 100, color: 'text.secondary', ...iconContainer }} />
            {title &&
                <Typography variant="h2" component='h2' sx={{ mt: 2 }} {...titleStyle}>
                    {title}
                </Typography>
            }
            {message &&
                <Typography variant="body1" sx={{ mt: 1, mb: 3 }} {...messageStyle}>
                    {message}
                </Typography>
            }
        </Stack>
    );
};

export default NoContentCard