import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AppLngSwitcher from "@crema/core/AppLngSwitcher";
import Box from "@mui/material/Box";
import AppSearchBar from "@crema/core/AppSearchBar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import { toggleNavCollapsed } from "../../../../../redux/actions";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import AppMessages from "../../../AppMessages";
import AppNotifications from "../../../AppNotifications";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppTooltip from "../../../AppTooltip";
import { alpha } from "@mui/material/styles";
import AppLogo from "../../components/AppLogo";
import UserInfo from "../../components/UserInfo";
import { setSelectedShip, shipsSelectors } from "../../../../../redux/reducers/Ships";
import { Ship } from "types/models/Ship";
import ShipSelect from "components/ShipSelector";
import HeaderButtonGroup from "./HeaderButtonGroup";

const AppHeader = () => {
  const ships: Ship[] = useAppSelector(shipsSelectors.selectAll);
  const selectedShip = useAppSelector((state) => state.ships.selectedShip);

  const dispatch = useAppDispatch();

  const handleShipChange = (id: number) => {
    dispatch(setSelectedShip(ships.find((s) => s.shipId === id)));
  };
  
  return (
    <AppBar
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.secondary",
        transition: "width 0.5s ease",
        width: "100%",
        height: (theme) => theme.spacing(20),
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 56, sm: 70 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
          backgroundColor: "background.secondary",
          height: "100%"
        }}
      >
        <Hidden lgUp>
          <IconButton
            sx={{
              color: "text.secondary",
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            "& .logo-text": {
              display: { xs: "none", sm: "block" },
            },
          }}
        >
          <AppLogo />
        </Box>
        {/* <Box
          sx={{
            flexGrow: 1,
          }}
        />
        <Box
          sx={{
            minHeight: 40,
            position: "relative",
            display: { xs: "none", sm: "block" },
            "& .searchRoot": {
              position: { xs: "absolute", sm: "relative" },
              right: { xs: 0, sm: "auto" },
              top: { xs: 0, sm: "auto" },
            },
          }}
        >
          {ships ? (
            <ShipSelect
              onShipChange={handleShipChange}
              selectedShip={selectedShip}
              ships={ships}
            />
          ) : null}
        </Box> */}
        {/* <Box
          sx={{
            minHeight: 40,
            position: "relative",
            display: { xs: "none", sm: "block" },
            "& .searchRoot": {
              position: { xs: "absolute", sm: "relative" },
              right: { xs: 0, sm: "auto" },
              top: { xs: 0, sm: "auto" },
            },
          }}
        >
          <AppSearchBar iconPosition="right" placeholder="Search…" />
        </Box> */}

        {/* <Box sx={{ ml: 4 }}>
          <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
        </Box>
 */}
        <Box
          sx={{
            width: '100%',
            ml: { xs: 0, sm: 4 },
            mr: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {/* <Hidden smDown> */}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              maxHeight: 40,
              // marginLeft: -2,
              // marginRight: -2,
            }}
          >
            <HeaderButtonGroup
              onShipChange={handleShipChange}
              selectedShip={selectedShip}
              ships={ships}
            />
            {/* <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppNotifications />
              </Box> */}
            {/* <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppMessages />
              </Box> */}
          </Box>
          {/* </Hidden> */}

          <Box
            sx={{
              mr: { xs: 4, sm: 0 },
              ml: { xs: 0, sm: 4 },
              // minWidth: { md: 220 },
              "& .user-info-view": {
                p: 0,
              },
              "& .user-info": {
                display: { xs: "none", md: "block" },
              },
            }}
          >
            <UserInfo />
          </Box>

          {/* <Hidden smUp>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppTooltip title="More">
                  <IconButton
                    sx={{
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      color: (theme) => theme.palette.text.secondary,
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      border: 1,
                      borderColor: "transparent",
                      "&:hover, &:focus": {
                        color: (theme) => theme.palette.text.primary,
                        backgroundColor: (theme) =>
                          alpha(theme.palette.background.default, 0.9),
                        borderColor: (theme) =>
                          alpha(theme.palette.text.secondary, 0.25),
                      },
                    }}
                    onClick={handleClick}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </AppTooltip>
              </Box>
            </Box>
          </Hidden> */}
          {/* <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <AppNotifications isMenu />
            </MenuItem>
            <MenuItem>
              <AppMessages isMenu />
            </MenuItem>
            <MenuItem>Setting</MenuItem>
          </Menu> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
