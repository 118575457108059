import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationConfigData } from '../../types/models/Notification';

const initialState: NotificationConfigData = {
    data: null,
    updateStatus: 'idle',
    status: 'idle',
    updateError: null,
    error: null
};

const notificationsConfigSlice = createSlice({
    name: 'notificationsConfig',
    initialState,
    reducers: {
        //get
        loadNotificationConfigStart(state) {
            state.status = 'loading';
        },
        loadNotificationConfigSuccess(state, action) {
            state.status = 'succeeded';
            state.data = action.payload.data;
            state.error = null;
        },
        loadNotificationConfigFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },

        // post - put 
        setNotificationConfigStart(state) {
            state.updateStatus = 'loading';
        },
        setNotificationConfigSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        setNotificationConfigFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        },

        resetNotificationsConfigUpdateStatus(state) {
            state.updateStatus = 'idle';
        },
    },

});

export const {
    loadNotificationConfigStart,
    loadNotificationConfigSuccess,
    loadNotificationConfigFailure,

    setNotificationConfigStart,
    setNotificationConfigSuccess,
    setNotificationConfigFailure,

    resetNotificationsConfigUpdateStatus,
} = notificationsConfigSlice.actions;

export default notificationsConfigSlice.reducer;