import { Dispatch } from "redux";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { setShips } from "../../redux/reducers/Ships";
import {loadStart, loadSuccess, loadFailure} from '../reducers/Core';

export const getShipList = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch(loadStart());
        jwtAxios
            .get(`/ships`)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(loadSuccess());
                    dispatch(setShips(res.data.data));
                } else {
                    dispatch(loadFailure(res?.data));
                }
            })
            .catch((error) => {
                const status = error?.response?.status;
                const message = error?.response?.data?.title || error?.message 
                dispatch(loadFailure({ status, message }));
            });
    };
};
