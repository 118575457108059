import React from "react";
import { authRouteConfig } from "./auth";
import Error403 from "./errorPages/Error403";
import { errorPagesConfigs } from "./errorPages";
import { samplePagesConfigs } from "./seaquest";
import { profilePage } from "./profile";
import { notificationPage } from "./notifications"

const authorizedStructure = {
  fallbackPath: "/",
  unAuthorizedComponent: <Error403 />,
  routes: [...samplePagesConfigs, ...profilePage, ...notificationPage],
};

const unAuthorizedStructure = {
  fallbackPath: "/",
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
