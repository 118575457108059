import { Ship } from "types/models/Ship";

export const mapShipDataByMetric = (
  data: Ship,
  metric: string
): any[] => {
  return []
}

export const mapShipDataToCourseMap = (
  data: Ship
): { lat: number; lon: number; timeAt: string }[] => {
  const lat = mapShipDataByMetric(data, "lat");
  const lon = mapShipDataByMetric(data, "lon");

  return lat?.map((r) => ({
    lat: r?.value,
    lon: lon?.find((item) => item.timeAt === r.timeAt).value,
    timeAt: r?.timeAt,
  }));
};


