import React, { useState, useEffect } from 'react';
import { Ship } from "types/models/Ship";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Typography, Stack } from '@mui/material';
import { ReactComponent as ArrowDown } from "../../assets/icon/arrow-down.svg";
import { ReactComponent as NoShipsIcon } from "../../assets/icon/noShips.svg";
interface ShipSelectProps {
  onShipChange: (id: number) => void;
  selectedShip: Ship;
  ships: Ship[];
}

const ShipSelect: React.FC<ShipSelectProps> = ({
  onShipChange,
  ships,
  selectedShip,
}) => {
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    if (selectedShip) {
      setSelectedId(selectedShip.shipId.toString());
    }
  }, [selectedShip]);

  const handleChange = (event) => {
    const shipId = event.target.value as string;
    setSelectedId(shipId);
    onShipChange(event.target.value as number);
  };

  if (!ships || ships.length === 0) {
    return (
      <Stack
        alignItems='center'
        justifyContent='center'
        px={5}
        sx={{ color: 'error.main', textAlign: 'center' }}
      >
        <NoShipsIcon />
      </Stack>
    );
  }


  return (
    <Select
      value={selectedId}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'Selecta Ship' }}
      MenuProps={{
        elevation: 0, //no shadow
        sx: {
          '& .MuiPaper-root': {
            mt: 2,
            bgcolor: 'blue.whale',
            backgroundImage: 'none',
          },
          '& .MuiMenu-list': { py: 1 },
        }
      }}
      IconComponent={(props) => <ArrowDown className={props.className} />}
      sx={{
        border: 0,
        borderRadius: '3px',
        mx: 'auto',
        "& .MuiSelect-select": {
          border: 0,
          color: "text.light",
          bgcolor: 'marine.main',
          py: 2,
          px: 4,
        },
        "& .MuiSelect-icon": {
          color: "text.light",
          fontSize: 10,
          mr: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.Mui-focused": {
          border: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
      }}
    >
      {ships?.map((ship) => (
        <MenuItem
          key={ship.shipId}
          value={ship.shipId}
          sx={{
            py: 1.5,
            px: 4,
            height: 32,
            '&:hover': {
              color: 'text.light',
              bgcolor: 'marine.main'
            },
            '&.Mui-selected': {
              color: 'tertiary.main',
              bgcolor: 'blue.whale',
              '&:hover': { bgcolor: 'marine.main' }
            },
          }}
        >
          <Typography variant="h5" sx={{ lineHeight: '24px' }}>
            {ship.shipName}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ShipSelect;