import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import AuthRoutes from "@crema/utility/AuthRoutes";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppLayout from "@crema/core/AppLayout";

import { BrowserRouter } from "react-router-dom";
import AppAuthProvider from "@crema/core/AppAuthProvider";
import { store } from "./redux/store"

const App = ({ instance }) => (
  <AppContextProvider >
    <Provider store={store}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <AppAuthProvider instance={instance} >
                  <AuthRoutes>
                    <CssBaseline />
                    <AppLayout />
                  </AuthRoutes>
                </AppAuthProvider>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
