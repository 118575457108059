import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { Sensor } from 'types/models/Sensor';

// Create an entity adapter
const sensorsAdapter = createEntityAdapter({
    selectId: (sensor: Sensor) => sensor.sensorId,
});

const initialState = sensorsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

const sensorsSlice = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        loadSensorsStart(state) {
            state.status = 'loading';
        },
        loadSensorsSuccess(state, action) {
            state.status = 'succeeded';
            sensorsAdapter.setAll(state, action.payload);
            state.error = null;
        },
        loadSensorsFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },
        clearSensors: (state) => {
            sensorsAdapter.removeAll(state);
            state.status = 'idle';
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const sensorsSelectors = sensorsAdapter.getSelectors<RootState>(
    (state) => state.sensors
);

export const {
    loadSensorsStart,
    loadSensorsSuccess,
    loadSensorsFailure,
    clearSensors
} = sensorsSlice.actions;

export default sensorsSlice.reducer;
