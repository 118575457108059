import { createSlice } from '@reduxjs/toolkit';
import { ShipCompliance } from 'types/models/ShipCompliance';


const initialState: ShipCompliance = {
    ciiMonthlyData: {
        data: null,
        status: 'idle',
        error: null,
    },
    ciiDailyData: {
        data: null,
        status: 'idle',
        error: null,
    },
};

const complianceSlice = createSlice({
    name: 'cii',
    initialState,
    reducers: {
        loadCiiMonthlyDataStart(state) {
            state.ciiMonthlyData.status = 'loading';
        },
        loadCiiMonthlyDataSuccess(state, action) {
            state.ciiMonthlyData.status = 'succeeded';
            state.ciiMonthlyData.data = action.payload.map(item => ({
                ciiAuxiliaryEngineEfficiency: item.ciiAuxiliaryEngineEfficiency,
                ciiEngineEfficiency: item.ciiEngineEfficiency,
                ciiFouling: item.ciiFouling,
                ciiOptimal: item.ciiOptimal,
                ciiTrim: item.ciiTrim,
                ciiWeather: item.ciiWeather,
                timestamp: item.timestamp
            }));
            state.ciiMonthlyData.error = null;
        },
        loadCiiMonthlyDataFailure(state, action) {
            state.ciiMonthlyData.status = 'error';
            state.ciiMonthlyData.error = action.payload;
        },

        // cii Daily
        loadCiiDailyDataStart(state) {
            state.ciiDailyData.status = 'loading';
        },
        loadCiiDailyDataSuccess(state, action) {
            state.ciiDailyData.status = 'succeeded';
            state.ciiDailyData.data = action.payload.map(item => ({
                ciiDay: item.ciiDay,
                timestamp: item.timestamp
            }));
            state.ciiDailyData.error = null;
        },
        loadCiiDailyDataFailure(state, action) {
            state.ciiDailyData.status = 'error';
            state.ciiDailyData.error = action.payload;
        },
        /* addCiiMonthlyData(state, action) {
            state.ciiMonthlyData = action.payload
        },
        addCiiDailyData(state, action) {
            state.ciiDailyData = action.payload
        }, */
    },
});

export const {
    loadCiiDailyDataStart,
    loadCiiDailyDataSuccess,
    loadCiiDailyDataFailure,
    loadCiiMonthlyDataStart,
    loadCiiMonthlyDataSuccess,
    loadCiiMonthlyDataFailure
} = complianceSlice.actions;

export default complianceSlice.reducer;