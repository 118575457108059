import { createApi, } from '@reduxjs/toolkit/query/react';
import jwtAxios from '../auth/jwt-auth';
import type { AxiosRequestConfig, AxiosError } from 'axios'
import { Sensor, SensorReading } from 'types/models/Sensor';
import { Electrical, ElectricalsList } from 'types/models/Electrical';

// Define a custom fetchBaseQuery using jwtAxios
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data, params }) => {
      try {
        const result = await jwtAxios({ url: baseUrl + url, method, data, params });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError as AxiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export const apiService = createApi({
  reducerPath: 'api', // or any other unique name
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_WEB_API, // Ensure this matches your environment variable
  }),
  tagTypes: ['SensorReadings', 'Electricals'],
  endpoints: (builder) => ({
    // Define your endpoints here, for example:
    getSensorReadings: builder.query({
      query: (queryArg) => {
        return { url: `subsystems/sensorreadings`, method: 'GET', data: undefined, params: queryArg }
      },
      transformResponse: (response, meta, arg) => response.data.sort((a: SensorReading, b: SensorReading) => String(a.timestamp).localeCompare(String(b.timestamp))),
      providesTags: (result, error, id): any => [{ type: 'SensorReadings', id }],
    }),
    getElectricals: builder.query({
      query: (queryArg) => {
        return { url: `electrical/data`, method: 'GET', data: undefined, params: queryArg }
      },
      transformResponse: (response) => ({
        data: response.data.data,
        anyThresholdExceeded: response.data.anyThresholdExceeded,
        sensorId: response.data.sensorId,
        message: response.message,
      }),
      providesTags: (result, error, id): any => [{ type: 'Electricals', id }],
      // keepUnusedDataFor: 0,
    }),

    // Add more endpoints as needed
  }),
});


export const { useGetSensorReadingsQuery, useGetElectricalsQuery } = apiService;