import React from "react";
import { SvgIcon } from "@mui/material";
import { Checkbox, CheckboxProps } from "@mui/material";
import { useThemeContext } from "@crema/utility/AppContextProvider/ThemeContextProvider";

const CustomCheckedIcon = ({ color }) => {
    const { theme } = useThemeContext();
    return (
        <SvgIcon viewBox="0 0 18 18" sx={{ width: 18, height: 18 }}>
            <rect width="18" height="18" fill={theme.palette.tertiary.main} />
            <path
                d="M14 5L7 12L4 9"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

const CustomCheckBoxOutlineBlankIcon: React.FC = () => {
    const { theme } = useThemeContext();
    return (
        <SvgIcon viewBox="0 0 18 18" style={{ width: 18, height: 18 }}>
            <rect
                x="0.5"
                y="0.5"
                width="17"
                height="17"
                stroke={theme.palette.tertiary.main}
                strokeWidth="2"
                fill="none"
            />
        </SvgIcon>
    );
};

interface AppCheckboxProps extends CheckboxProps {
    [x: string]: any;
}

const AppCheckbox: React.FC<AppCheckboxProps> = ({
    checked,
    onChange,
    ...rest
}) => {
    const { theme } = useThemeContext();
    return (
        <Checkbox
            sx={{
                color: theme.palette.text.light,
                "&.Mui-checked": {
                    color: theme.palette.tertiary.main,
                },
                "& .MuiSvgIcon-root": {
                    color: theme.palette.tertiary.main,
                },
            }}
            icon={<CustomCheckBoxOutlineBlankIcon />}
            checkedIcon={<CustomCheckedIcon color={theme.palette.text.light} />}
            onChange={onChange}
            checked={checked}
            {...rest}
        />
    );
};

export default AppCheckbox;
