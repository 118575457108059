import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElectricalFaultsList} from 'types/models/Electrical';

const initialState = {
    data: null,
    status: 'idle',
    error: null,
};

const electricalsFaultsSlice = createSlice({
    name: 'electricalsFaults',
    initialState,
    reducers: {
        loadElectricalsFaultsStart(state) {
            state.status = 'loading';
        },
        loadElectricalsFaultsSuccess(state, action: PayloadAction<ElectricalFaultsList>) {
            state.data = action.payload;
            state.status = 'succeeded'
        },
        loadElectricalsFaultsFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },
    }
});

export const {
    loadElectricalsFaultsStart,
    loadElectricalsFaultsSuccess,
    loadElectricalsFaultsFailure
} = electricalsFaultsSlice.actions;

export default electricalsFaultsSlice.reducer;