import { Dispatch } from "redux";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { appIntl } from "@crema/utility/helper/Utils";
import {
    loadNotificationConfigStart,
    loadNotificationConfigSuccess,
    loadNotificationConfigFailure,
    setNotificationConfigStart,
    setNotificationConfigSuccess,
    setNotificationConfigFailure,
} from "../../redux/reducers/NotificationsConfig";

import {
    setStart,
    setSuccess,
    setFailure
} from "../../redux/reducers/Common";

export const getNotificationConfig = (shipId: number, timezone: string) => {
    const { messages } = appIntl();
    return (dispatch: Dispatch<any>) => {
        dispatch(loadNotificationConfigStart());
        jwtAxios.get(`/notificationconfiguration/${shipId}`, {
            params: {
                'TimeZoneId': timezone,
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(loadNotificationConfigSuccess(res?.data));
                } else {
                    dispatch(loadNotificationConfigFailure(String(messages["message.somethingWentWrong"])));
                }
            })
            .catch((error) => {
                dispatch(loadNotificationConfigFailure(error.response?.data?.message));
            });
    };
};

// This function sends a POST request to the server to create the notification configuration
// The request can also be handled by the server as a PUT request (update).
export const setNotificationConfig = (
    shipId: number,
    intervalType: string,
    notificationTime: string,
    notificationDay: string | null,
    timeZoneId: string,
    applyToAll: boolean
) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(setStart());
        dispatch(setNotificationConfigStart());
        jwtAxios.post(`/notificationconfiguration/${shipId}`, {
            'IntervalType': intervalType,
            'NotificationTime': notificationTime,
            'NotificationDay': notificationDay,
            "TimeZoneId": timeZoneId,
            "ApplyToAll": applyToAll,
        })
            .then((res) => {
                if (res.status === 201 || res.status === 200) {
                    dispatch(setSuccess(res?.data.message))
                    dispatch(setNotificationConfigSuccess(res?.data));
                } else {
                    dispatch(setFailure(res?.data?.message))
                    dispatch(setNotificationConfigFailure(res?.data?.message));
                }
            })
            .catch((error) => {
                dispatch(setFailure(error?.message))
                dispatch(setNotificationConfigFailure(error.response?.data?.message));
            });
    };
};