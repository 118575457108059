import { createSlice } from '@reduxjs/toolkit';
import { SensorsWarnings, WarningsListData } from 'types/models/Sensor';

const initialState: SensorsWarnings = {
    data: null,
    status: 'idle',
    error: null
};

const sensorsWarningsSlice = createSlice({
    name: 'sensorsWarnings',
    initialState,
    reducers: {
        loadSensorsWarningsStart(state) {
            state.status = 'loading';
        },
        loadSensorsWarningsSuccess(state, action) {
            state.status = 'succeeded';
            state.data = action.payload.map((item: WarningsListData) => ({
                warningType: item.warningType,
                warningName: item.warningName,
                explanation: item.explanation,
                color: item.color,
            }));
            state.error = null;
        },
        loadSensorsWarningsFailure(state, action) {
            state.status = 'error';
            state.error = action.payload;
        },

    },
});

export const {
    loadSensorsWarningsStart,
    loadSensorsWarningsSuccess,
    loadSensorsWarningsFailure
} = sensorsWarningsSlice.actions;

export default sensorsWarningsSlice.reducer;